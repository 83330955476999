<template>
  <div class="page">
    <header-bar color="#ffffff"/>
    <div class="header-title">
      <span>{{ $t('base_info_title') }}</span>
    </div>
    <div class="user-info">
      <div class="info-item">
        <span class="item-desc">1.What is your job?</span>
        <el-input class="item-value" v-model="modifiedUser.job" :disabled="!!user.job && !couldModify"
                  :class="{'readonly': !!user.job && !couldModify }"
                  :placeholder="$t('base_info_job_profession_input_placeholder')"/>
      </div>
      <div class="info-item">
        <span class="item-desc">2.How many total monthly income?</span>
        <el-input class="item-value" v-model="modifiedUser.monthlyIncome"
                  :disabled="!!user.monthlyIncome && !couldModify"
                  :class="{'readonly': !!user.monthlyIncome && !couldModify }"
                  :placeholder="$t('common_input_placeholder')"/>
      </div>
      <div class="info-item">
        <span class="item-desc">3.How much total expenses per month?</span>
        <el-input class="item-value" v-model="modifiedUser.expenses" :disabled="!!user.expenses && !couldModify"
                  :class="{'readonly': !!user.expenses && !couldModify }"
                  :placeholder="$t('common_input_placeholder')"/>
      </div>
      <div class="info-item">
        <span class="item-desc">4.What is the purpose of your loan ?</span>
        <el-input class="item-value" v-model="modifiedUser.loanPurpose" :disabled="!!user.loanPurpose && !couldModify"
                  :class="{'readonly': !!user.loanPurpose && !couldModify }"
                  :placeholder="$t('base_info_detail_address_input_placeholder')"/>
      </div>
      <div class="info-item radio-div">
        <span class="item-desc">5. Have you ever applied for a loan or have credit with our company or not?</span>
        <van-radio-group v-model="modifiedUser.loanBefore" :disabled="user.loanBefore != null && !couldModify"
                         class="radio"
                         :class="{'readonly': user.loanBefore != null && !couldModify }" direction="horizontal">
          <van-radio :name="true">YES</van-radio>
          <van-radio :name="false">NO</van-radio>
        </van-radio-group>
      </div>
      <div class="info-item radio-div">
        <span class="item-desc">6. Are you okay if company need you to guarantee for your loan</span>
        <van-radio-group v-model="modifiedUser.guarantee" :disabled="user.guarantee != null && !couldModify"
                         direction="horizontal"
                         class="radio" :class="{'readonly': user.guarantee != null && !couldModify }">
          <van-radio :name="true">YES</van-radio>
          <van-radio :name="false">NO</van-radio>
        </van-radio-group>
      </div>
    </div>
    <van-button class="submit-btn" :loading="loading" :disabled="disabled" :class="{'disabled': disabled}"
                size=small @click="submit">
      {{ $t('common_submit') }}
    </van-button>
    <van-overlay :show="showContact" class-name="contact-box">
      <div class="wrapper" @click.stop>
        <img :src="successImg">
        <span class="contact-title">{{ $t('apply_loan_success') }}</span>
        <div class="btn-div">
          <van-button type="info" block @click="store.contactUs" v-if="store.showContact">
            {{ $t('click_to_contact_service') }}
          </van-button>
          <van-button type="info" block @click="viewOrder" v-else>
            {{ $t('common_check') }}
          </van-button>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import titleImg from '../../assets/baseInfo-title.png';
import {orderService, userService} from "@/services";
import store from '@/store';
import successImg from "@/assets/success.png";

export default {
  name: "InformationQuestion",
  data() {
    return {
      store,
      titleImg,
      successImg,
      modifiedUser: {},
      loading: false,
      user: {},
      couldModify: false,
      showContact: false,
      loanOrderId: null,
    }
  },
  computed: {
    disabled() {
      return !this.modifiedUser.job || !this.modifiedUser.monthlyIncome || !this.modifiedUser.expenses
          || !this.modifiedUser.loanPurpose || this.modifiedUser.loanBefore == null || this.modifiedUser.guarantee == null
    }
  },
  async mounted() {
    this.user = await store.getUser();
    this.modifiedUser = await store.getModifiedUser();
    this.couldModify = this.user.userQualificationView.basicInformation !== 1;
  },
  methods: {
    async submit() {
      let params = {
        realName: this.modifiedUser.realName,
        identity: this.modifiedUser.identity,
        marriage: this.modifiedUser.marriage,
        job: this.modifiedUser.job,
        monthlyIncome: this.modifiedUser.monthlyIncome,
        expenses: this.modifiedUser.expenses,
        loanPurpose: this.modifiedUser.loanPurpose,
        loanBefore: this.modifiedUser.loanBefore,
        guarantee: this.modifiedUser.guarantee,
      }
      await userService.updateUser(params);
      this.loading = false;
      store.updateModifiedUser(this.modifiedUser)
      store.getUser(true).then((user) => this.user = user);
      let loanProgress = await orderService.getLoanProgress();
      if (!loanProgress.status || loanProgress.status > 3 ) {
        // store.getAppList().then((appList) => {
        //   this.$router.push("/apply/" + appList[0].id);
        // });
        this.applyOrder();
      } else {
        this.$router.push("/detail/" + loanProgress.orderId);
      }
    },
    async viewOrder() {
      this.$router.push("/detail/" + this.loanOrderId);
    },
    async applyOrder() {
      let amountRange = await store.getAmountRange();
      let periodRange = await store.getPeriodRange();
      let periodConfigs = await store.getPeriodConfig();
      let params = {
        amt: amountRange[amountRange.length-1],
        configId: periodConfigs.id,
        periodDays: periodRange[periodRange.length-1],
        appId: process.env.DEPLOY_CONFIGS.appId
      }
      orderService.apply(params)
          .then((res) => {
            this.loanOrderId = res.loanOrderId;
            this.showContact = true;
          })
          .catch(() => this.loading = false);
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  padding-top: 44px;
  background-color: @base-color;
  min-height: calc(100% - 44px);
  display: flex;
  flex-direction: column;

  .header-title {
    padding: 15px 0;

    span {
      color: #ffffff;
      margin: auto;
      text-align: center;
      display: block;
      font-size: 28px;
      font-weight: 700;
    }
  }


  .user-info {
    margin: 0 10px calc(15% + 30px);
    padding: 10px;
    background: #247ADF;
    border-radius: 10px;
    color: #ffffff;
    flex-grow: 1;
    background: linear-gradient(179.61deg, #F37E00 -16.96%, #FFCA73 53.7%, rgba(255, 202, 115, 0) 95.96%);

    background-size: contain;
    background-position: bottom;

    .info-item {
      margin: 10px 0;

      .item-desc {
        font-size: 14px;
        margin: 10px 0;
        display: block;
      }

      .item-value {
        /deep/ input.el-input__inner {
          border: 1px solid #7972E6;
          border-radius: 8px;
          height: 45px;
          line-height: 45px;
        }
      }
    }

    .radio-div {
      margin: 20px 0;
    }

    .radio {
      font-size: 14px;
      .van-radio {
        margin-right: 20%;

        /deep/ .van-radio__icon {
          font-size: 14px;
        }

        /deep/ .van-radio__label {
          color: #ffffff;
        }

        /deep/ .van-radio__icon--checked {
          .van-icon {
            color: #1989fa;
            background-color: #ffffff;
            border-color: #ffffff;
          }
        }
      }
    }
  }

  .submit-btn {
    position: fixed;
    bottom: 20px;
    width: calc(100% - 40px);
    background: @button-color;
    border: none;
    height: 50px;
    border-radius: 8px;
    color: #ffffff;
    font-size: 18px;
    margin: 0 20px;
  }

  .contact-box {
    background: rgba(0, 0, 0, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper {
      background: #FFFFFF;
      margin: 50px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 25px 20px;
      border-radius: 6px;
      font-size: 12px;

      img {
        width: 40%;
        margin-bottom: 10px;
      }

      .contact-title {
        font-size: 26px;
        font-weight: 600;
        color: #191919;
        margin: 8px 0 3px;
      }

      .btn-div {
        display: flex;
        flex-direction: row;
      }

      /deep/ .van-button {
        height: 45px;
        width: 150px;
        font-size: 16px;
        background: @button-color;
        border-radius: 8px;
        margin: 15px 10px 0;
      }
    }
  }
}
</style>
